import isMobile from './src/utility/isMobile'

document.addEventListener('DOMContentLoaded', function () {
  const words = document.querySelector('.screenshot-words');
  if (!words) return;

  words.innerHTML = words.innerHTML.split(/\s+|&nbsp;/g)
  .map((word) => {
    return word.replace(/(>| |^)([?!.,)(\-"%$':;¿—\w+À-ÿ]{1,})((?=(<| |$)))/g, `$1<span data-word>$2</span>$3`)
  }).join(' ')

  const moveOnScroll = () => {
    const position = window.pageYOffset - words.getBoundingClientRect().top
    const translate = position - (position*1.2)
    words.style = `transform: translateY(${100 + translate}px);`
  }

  const io = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        window.addEventListener('scroll', moveOnScroll)
      } else {
        window.removeEventListener('scroll', moveOnScroll)
      }
    })
  }, {
    rootMargin: `0px 0px ${ isMobile() ? '150px' : '0px' } 0px`,
    threshold: 0
  })

  // Declares what to observe, and observes its properties.
  const boxElList = document.querySelectorAll('.screenshot-words');
  boxElList.forEach((el) => {
    io.observe(el);
  })

  // --------------

  const wordIo = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.style = 'color: gray'
      } else {
        entry.target.style = 'color: white'
      }
    })
  }, {
    rootMargin: "0px 0px -70% 0px",
    threshold: 0
  })

  // Declares what to observe, and observes its properties.
  const wordList = document.querySelectorAll('[data-word]');
  wordList.forEach((el) => {
    wordIo.observe(el);
  })

  // ----------------

  let floatingElements = new Set()
  const float = (target) => {
    Array.from(floatingElements).forEach((floating) => {
      let { rotate, speed, direction } = floating.dataset;
      speed = parseFloat(speed || '1.05')

      if (rotate) {
        rotate = rotate == 'left' ? 1 : -1
      }

      const position = window.pageYOffset - floating.getBoundingClientRect().top
      const translate = position - (position * speed )

      let translateAmount;
      translateAmount =  direction == "down" ? 50 - translate : 50 + translate

      if (rotate) {
        floating.style.transform = `translateY(${translateAmount - 20}px) rotate(${rotate * translate*.15}deg)`
      } else {
        floating.style.transform = `translateY(${translateAmount}px)`
      }
    })
  }

  float()

  const floatIo = new IntersectionObserver(entries => {
    const floatOnScroll = () => float()
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        floatingElements.add(entry.target)
        window.addEventListener('scroll', floatOnScroll)
      } else {
        if (floatingElements.length > 1) {
          window.removeEventListener('scroll', floatOnScroll)
          floatingElements.delete(entry.target)
        }
      }
    })
  }, {
    rootMargin: `0px 0px  ${ isMobile() ? '50px' : '10px' } 0px`,
    threshold: 0
  })

  // Declares what to observe, and observes its properties.
  const floatList = document.querySelectorAll('.float');
  floatList.forEach((el) => {
    floatIo.observe(el);
  })
});
